@import './variables.scss';
@import './acg-reset.scss';
@import './custom-kendo-grid.scss';
@import './login.scss';
@import './layout.scss';

/*---------------------User Module Styles Start---------------------------*/

.user-module {
	.page-content-body {
		height: calc(100% - 5.4375rem);

		.userlist-box-container {
			padding: 1.1875rem 1.25rem 1.1875rem 1.875rem;
			height: calc(100% - 3.125rem);

			.default-kendo-grid {
				td {
					overflow: visible;

					li {
						list-style: none;
						width: 100%;
						float: left;
						display: block;
						line-height: 2rem !important;

						&:last-child {
							display: inline-block;
							width: auto;
							max-width: calc(100% - 3.125rem);
						}
					}

					.count-outer {
						width: 1.75rem;
						margin-left: 0.5rem;
						min-width: 1.75rem;
						height: 1.75rem;
						border-radius: 0.875rem;
						overflow: hidden;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						background-color: var(--warning-lite-color);
					}

					.count-badge {
						width: 100%;
						min-width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: var(--fz-12px);
						line-height: 1.125rem;
						font-weight: 700;
						color: var(--theme-color);
					}

					.dropdown-toggle.btn.btn-primary {
						background-color: transparent !important;
						border: 0 !important;
						color: var(--dark-color) !important;

						&:after {
							display: none;
						}
					}
					.dropdown {
						&.show {
							.dropdown-toggle.btn.btn-primary {
								color: var(--kendo-group-bg-color) !important;
								background-color: var(--grid-action-btn) !important;
							}
						}
					}
				}

				.k-grid-pager {
					position: absolute;
					right: -1.25rem;
					bottom: -4.5rem;
				}
			}
		}
	}
}

.k-dialog-wrapper {
	background: transparent;

	.k-overlay {
		background-color: var(--theme-dark-color);
	}

	&.window-right {
		.k-window.k-dialog {
			position: fixed;
			right: 0;
			top: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			padding: 1.4375rem 2.5rem 0 2.5rem;

			.k-dialog-titlebar {
				background-color: var(--white-color);
				padding: 0 0 1.25rem 0;

				.k-window-title {
					font-weight: 700;
					font-size: 22px;
					line-height: 150%;
					color: var(--theme-dark-color);
				}

				.k-window-titlebar-actions {
					.k-icon.k-i-x.k-button-icon {
						border: 0 !important;
						box-shadow: none !important;
						outline: 0 none !important;
					}
				}
			}

			.k-window-content {
				margin: 1.875rem 0 0 0;
				padding: 0;
			}
		}
	}

	&.width-452px {
		.k-window.k-dialog {
			max-width: 28.25rem;
		}
	}
}

.add-user-popup {
	.k-window-content {
		height: 100%;

		.default-form-style {
			height: calc(100% - 5rem);

			.k-form-fieldset {
				height: 100%;
			}
		}
	}
}

.tooltip-inner {
	max-width: 15rem !important;
	padding: 0 !important;
	min-height: 2.313rem;
	min-width: 2.313rem;
	.tooltip-wrapper {
		padding: 0.5rem;
		display: flex;
		text-align: left;
		min-height: 2.313rem;
		min-width: 2.313rem;
		word-break: break-word;
		.content {
			* {
				margin: 0;
			}
		}
	}
	span.ms-2 {
		height: 1.1rem;
	}

	.editable-tooltip-content {
		position: relative;
		padding-bottom: 2.25rem;
		.btnClose {
			position: absolute;
			right: 0px;
			top: 10px;
			padding: 0 !important;
			color: var(--black-color) !important;
			font-size: var(--fz-18px) !important;
			border-radius: 0.2rem !important;
			height: 30px;
			width: 30px;
			min-width: 30px;
			background-color: transparent !important;
			border: 0 !important;
			box-shadow: none !important;
			outline: none !important;
			z-index: 9;
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				color: var(--black-color) !important;
			}
			// svg {
			// 	display: none;
			// }
			// .k-button-text:before {
			// 	content: 'Cancel';
			// }
		}
		.updateBtn {
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 0.25rem 0.5rem !important;
			font-size: var(--fz-12px) !important;
			border-radius: 0.2rem !important;
			height: 1.75rem;
		}
	}
}
.tooltip.show {
	opacity: 1 !important;
	z-index: 120;
}
.editable-tooltip-wrapper {
	max-width: 40rem;
	width: 100%;
	.tooltip-inner {
		width: 100%;
		max-width: 40rem !important;
		-webkit-box-shadow: 0 0 10px 1px var(--border-color-secondary) !important;
		box-shadow: 0 0 10px 1px var(--border-color-secondary) !important;
	}
	.editable-tooltip-content {
		width: 100%;
	}

	.tooltip-arrow::before {
		--bs-tooltip-bg: #ffffff;
	}
	.tooltip-inner {
		background-color: var(--white-color) !important;
	}
	.k-editor-content > .k-iframe {
		width: 100% !important;
	}
	.k-editor-toolbar {
		.k-button {
			&.k-selected {
				background-color: var(--primary-color);
				border-color: var(--primary-color);
			}
		}
	}
}
.custom-tooltip-list {
	margin: 0 !important;

	li {
		width: 100%;
		display: block;
		padding: 0.75rem 1.3125rem !important;
		font-weight: 600;
		font-size: var(--fz-14px);
		color: var(--theme-light-color) !important;
		position: relative;
		border-bottom: 0.125rem solid rgba(39, 46, 57, 0.5);
		text-align: left;

		&:last-child {
			border-bottom: 0;
		}
	}
}
.dark-theme {
	.default-offcanvas {
		.form-control-switch-btn {
			background-color: var(--form-control-bg) !important;
			border: 1px solid var(--border-color-secondary) !important;
		}
	}
}
.grid-custom-container{
	padding-right: 0.75rem !important;
	.dummy-skelton{
		width: calc(100% - 1rem) !important;
	}
	.grid-custom-container-inner{
		overflow-y: auto;
		height: 100% !important;
		padding-right: 0.5rem;
		.default-kendo-grid{
			min-height: 30rem !important;
			.k-grid-header-wrap, .k-grid-container, .k-grid-content, .k-grid-aria-root{
				overflow: visible;
			}
		}
		.k-grid-pager{
			position: fixed !important;
			right: 2rem !important;
			bottom: 1.9375rem !important;
		}
		&.with-toolbar{
			padding-top: 3.65rem;
			.k-toolbar{
				position: fixed;
				right: 3.22rem;
				left: 8.65rem;
				margin-top: -3.9rem;
				background-color: var(--box-container-bg) !important;
				z-index: 9;
			}
			.k-grid-header{
				padding-right: 0;
			}
		}
	}
}

/*---------------------User Module Styles End---------------------------*/

/*---------------------Role Module Styles Start---------------------------*/

.role-module {
	.page-content-body {
		height: calc(100% - 5.4375rem);

		.rolelist-box-container {
			padding: 1.1875rem 1.875rem;
			height: calc(100% - 3.125rem);

			.default-kendo-grid {
				.k-grid-pager {
					position: absolute;
					right: -1.25rem;
					bottom: -4.5rem;
				}

				.k-switch {
					.k-switch-label-on,
					.k-switch-label-off {
						display: none;
					}
				}
			}
		}
	}
}

/*---------------------Role Module Styles End---------------------------*/

/*---------------------Settings Page Styles Start---------------------------*/

.settings-page {
	.page-content-body {
		height: calc(100% - 4.4375rem);

		.settings-box-container {
			padding: 1.3125rem 0.5625rem 1.3125rem 1.5625rem;
			height: 100%;

			.settings-layout-container {
				max-height: calc(100% - 3.5rem);
				height: 100%;
				padding-right: 1rem;
				overflow-y: auto;
				.form-label-gs {
					font-weight: 600;
					font-size: var(--fz-14px);
					line-height: 150%;
					color: var(--theme-text-color-secondary);
					margin-bottom: 0.1875rem;
				}
				.gs-cb-wrapper {
					padding: 0.8125rem;
					border: 1px solid var(--border-color-secondary) !important;
					border-radius: 0.3125rem;
				}
				.inner-column-wrapper {
					position: relative;
					padding: 1rem;
					background-color: rgba(var(--body-color-rgb), 0.4);
					border-radius: 0.375rem;
					// height: calc(100% - 3rem);
					&.user-height {
						margin-bottom: 0.5rem;
						height: auto;
					}

					.form-group {
						&.pb-10px {
							padding-bottom: 0.625rem !important;
						}

						.form-label {
							display: unset;

							& > div {
								display: unset;
							}

							.info-icon {
								margin-left: 0.25rem;
								line-height: 150%;
								vertical-align: middle;
							}
						}
					}

					.row {
						margin-left: -0.3125rem !important;
						margin-right: -0.3125rem !important;

						.gutter-10px {
							padding-left: 0.3125rem !important;
							padding-right: 0.3125rem !important;
						}
					}

					.form-control {
						background-color: var(--form-control-bg);
						border: 1px solid var(--border-color-secondary);
						border-radius: 0.3125rem;
						padding: 0.5125rem 1rem;
						font-weight: 600;
						font-size: var(--fz-14px);
						line-height: 1.3125rem;
						color: var(--theme-dark-color);
						height: 2.75rem;
						font-family: 'Gotham-Book', sans-serif !important;
						display: flex;
						align-items: center;
						&.text-area {
							padding-top: 0.7rem;
						}
					}
					.password-length-input,
					.date-format-input {
						padding: 0.5125rem 0.5rem 0.5125rem 1rem;
						.k-input-inner {
							padding-block: unset;
							padding-inline: unset;
							width: calc(100% - 1.5rem);
							display: inline-flex;
						}
						.k-input-button {
							width: 1.5rem;
						}
						.k-i-caret-alt-down::before {
							content: '\e015';
						}
					}
				}

				.hr-24-column {
					margin-top: 0.2rem;
				}

				.section-title {
					font-size: var(--fz-14px);
					font-weight: 600;
					margin-bottom: 1.1875rem;
					line-height: 1.3125rem;
					color: var(--theme-dark-color);
				}

				.section-subtitle {
					font-size: var(--fz-14px);
					font-weight: 600;
					display: inherit;
					margin-bottom: 0.4375rem;
					line-height: 1.3125rem;
					color: var(--theme-text-color-primary);

					& > div {
						display: unset;
					}

					&.attempt-title {
						max-width: 13rem;
						display: inherit;
					}

					.info-icon {
						margin-left: 0.25rem;
						color: var(--theme-text-color-primary);
						line-height: 150%;
						vertical-align: middle;
					}
				}

				.row.mb-3 {
					position: relative;

					.error-msg {
						font-size: var(--fz-12px) !important;
						font-weight: 600;
						color: var(--danger-lite-color) !important;
						position: absolute;
						bottom: -1rem;
						left: 0.75rem;
						margin: 0 !important;
					}
				}

				// .password-expiry-input,
				// .password-length-input,
				// .password-notify-input,
				// .lock-attempt-input,
				// .autolock-time-input,
				// .date-format-input {
				//   max-width: 7.5rem;
				//   width: 100%;
				// }

				.image-main-wrapper {
					display: flex;
					flex-direction: column;

					.image-wrapper {
						width: 100%;
						min-width: 6.25rem;
						max-width: 6.25rem;
						height: 100%;
						min-height: 6.25rem;
						max-height: 6.25rem;
						margin: 1rem auto;
						position: relative;
						border: 1px solid var(--border-color-secondary);
						overflow: hidden;

						.crop-image {
							width: calc(6.25rem - 2px);
							height: calc(6.25rem - 2px);
						}

						.close-btn {
							color: var(--theme-dark-color);
							background-color: var(--theme-light-color);
							position: absolute;
							right: 1px;
							top: 1px;
							height: 14px;
							width: 14px;
							display: inline-flex;
							align-items: center;
							justify-content: center;
							border: 0;
							box-shadow: none !important;
							outline: none !important;
						}
					}

					.browse-btn {
						margin-top: 0.5rem;
						max-width: 100px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.btn-footer-wrapper {
				display: flex;
				justify-content: end;
				align-items: flex-end;
				height: 3.5rem;
				padding-right: 1rem;
			}
			&.with-nav-tabs {
				background-color: transparent;
				border-radius: 0;
				padding: 0;
				.settings-layout-container {
					max-height: 100%;
					overflow-y: visible;
					.default-nav-tab-wrapper {
						height: 100%;
						.k-content {
							height: calc(100% - 5.625rem);
							overflow: visible !important;
							padding: 1.3125rem 0.5625rem 1.3125rem 1.5625rem;
							> .k-animation-container.k-animation-container-relative {
								width: 100% !important;
								height: 100%;
								overflow-y: auto;
							}
						}
					}
				}
			}
		}
	}
}

.default-nav-tab-wrapper {
	.k-tabstrip-items-wrapper {
		background-color: var(--box-container-bg);
		border-radius: 0.625rem;
		overflow: hidden;
		margin-bottom: 1.25rem;
		border: 0 !important;
		height: 4.375rem;
		overflow-x: auto;
		.k-tabstrip-items {
			border: 0 !important;
			box-shadow: none !important;
			outline: none !important;
			flex-wrap: nowrap;
			.k-item {
				padding: 1.5rem 2.375rem;
				border: 0 !important;
				box-shadow: none !important;
				outline: none !important;
				border-bottom: 0.25rem solid var(--box-container-bg) !important;
				background-color: var(--box-container-bg);
				color: var(--grid-pg-btn-color);
				line-height: 130%;
				font-size: var(--fz-18px);
				font-weight: 700;
				height: 4.375rem;
				&.k-active {
					border-bottom: 0.25rem solid var(--primary-color) !important;
					color: var(--primary-color);
				}
				&.k-disabled {
					cursor: not-allowed;
					pointer-events: unset;
					.k-link {
						cursor: not-allowed;
					}
				}
			}
		}
	}
	.k-content {
		border: 0 !important;
		border-radius: 0.625rem !important;
	}
}
.max-w-155px {
	max-width: 9.6875rem;
}

.default-upload-wrapper {
	background-color: rgba(var(--info-color-rgb), 0.15);
	height: 5.625rem;
	border-radius: 0.438rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	.browse-outer {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.browse-inner {
		display: flex;
		align-items: center;
	}

	p,
	label {
		font-size: var(--fz-13px);
		line-height: 1.25rem;
		color: var(--theme-dark-color);
		margin: 0;
	}
	.browse-btn {
		padding: 0 !important;
		border: 0 !important;
		box-shadow: none !important;
		outline: none !important;
		background-color: transparent !important;
		margin: 0 !important;
		color: var(--primary-color);
		text-decoration: underline;
		font-size: var(--fz-13px);
		line-height: 1.25rem;
		font-weight: 600;
		cursor: pointer;
	}
	.dragdrop {
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		opacity: 0;
		cursor: pointer;
	}
	&.disabled {
		cursor: not-allowed;
		.browse-btn {
			cursor: not-allowed;
		}
		.dragdrop {
			display: none;
		}
	}
}
.validation-wrapper {
	position: relative;
	.error-msg {
		font-size: var(--fz-12px) !important;
		font-weight: 600;
		color: var(--danger-lite-color) !important;
		position: absolute;
		bottom: -0.25rem;
		right: 1rem;
		margin: 0 !important;
	}
}
.common-input-field {
	&.w-100 {
		width: 100%;
	}
	.css-13cymwt-control,
	.css-t3ipsp-control {
		background-color: var(--form-control-bg) !important;
		border: 1px solid var(--border-color-secondary) !important;
		border-radius: 0.3125rem !important;
		padding: 0 1rem !important;
		font-weight: 600 !important;
		font-size: var(--fz-14px) !important;
		line-height: 1.3125rem !important;
		color: var(--theme-dark-color) !important;
		min-height: 2.75rem;
		max-height: 5rem;
		overflow-y: auto;
		font-family: 'Gotham-Book', sans-serif !important;
		display: flex;
		align-items: center;
		cursor: text;

		.css-1fdsijx-ValueContainer {
			padding: 0 !important;
			height: auto !important;
			.css-qbdosj-Input {
				padding: 0 !important;
				margin: 0 !important;
				input {
					font-weight: 600 !important;
					font-size: var(--fz-14px) !important;
					line-height: 1.3125rem !important;
					color: var(--theme-dark-color) !important;
				}
			}
		}
		.css-1hb7zxy-IndicatorsContainer {
			display: none !important;
		}
		.css-1jqq78o-placeholder {
			color: var(--input-text-color) !important;
			font-size: var(--fz-12px) !important;
			font-weight: 400 !important;
			margin-left: 0px !important;
			margin-right: 0px !important;
		}
		.css-1p3m7a8-multiValue,
		.css-wsp0cs-MultiValueGeneric {
			background-color: rgba(var(--light-color-rgb), 0.3) !important;
			color: var(--theme-text-color-secondary) !important;
			font-size: var(--fz-12px) !important;
		}
	}
}
.common-input-field.w-100.email-multiselect-d-none {
	.css-1wy0on6 {
		display: none !important;
	}
	.css-3w2yfm-ValueContainer {
		padding: 0 !important;
	}
}

/*---------------------Settings Page Styles End---------------------------*/

/*-------------------Teanant Page Styles Start----------------------------*/
.tenant-box-container {
	background-color: transparent;
}

.tenent-wrapper {
	padding: 0;

	.k-card {
		padding: 1.25rem;
		overflow: visible;
		background-color: var(--card-color-bg);

		.k-card-header {
			padding: 0;
			border: 0;
			width: calc(100% - 5rem);

			.k-card-title {
				font-size: var(--fz-18px);
				font-weight: 600;
				line-height: 1.4375rem;
				color: var(--secondary-dark-color);
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				width: 100%;
			}
		}

		.k-card-body {
			padding: 0;

			.two-column-card {
				display: flex;
				justify-content: space-between;
				border-bottom: 2px solid var(--body-color);
				border-radius: 0.125rem;

				.column-inner {
					padding: 1.0625rem 0.375rem 0.75rem 0.375rem;
					min-width: 6.5rem;
				}

				&.last {
					border-bottom: 0;

					.column-inner {
						padding: 1.0625rem 0.375rem 0 0.375rem;
					}
				}

				.card-label {
					font-size: var(--fz-12px);
					line-height: 1.125rem;
					color: var(--grid-pg-btn-color);
					font-family: 'Gotham-Book', sans-serif !important;
				}

				.card-value {
					font-size: var(--fz-14px);
					line-height: 1.3125rem;
					color: var(--theme-text-color-secondary);
					font-weight: 600;
				}
			}
		}

		.ellipsis-dropdown {
			position: absolute;
			right: 0.6875rem;
			top: 0.6875rem;

			.dropdown-menu {
				right: -0.5rem !important;
				left: unset !important;
				transform: unset !important;
				top: 2.25rem !important;

				&:after {
					content: '';
					position: absolute;
					top: -0.5rem;
					right: 0.85rem;
					width: 1.0625rem;
					height: 1.0625rem;
					border-radius: 0.1875rem;
					box-shadow: 0px 0px 0.75rem rgba(0, 0, 0, 0.1) !important;
					transform: rotate(45deg);
					background-color: var(--kendo-group-bg-color);
					z-index: 1;
				}

				&:before {
					content: '';
					position: absolute;
					top: 0rem;
					right: 0.25rem;
					width: 2.0625rem;
					height: 1.0625rem;
					background-color: var(--kendo-group-bg-color);
					z-index: 10;
				}
			}

			.k-button {
				background-color: transparent !important;
				border: 0 !important;
				box-shadow: none !important;
				outline: none !important;
			}
		}
	}
}

.tenant-search {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	max-width: 31rem;
	width: 100%;

	.form-control {
		margin-right: 0.5rem;
	}

	.form-group {
		display: flex;
		align-items: center;
		padding-bottom: 0;
		width: 100%;

		.form-label {
			min-width: 6.3rem;
			margin-right: 0.5rem;
			white-space: nowrap;
			display: none;
		}
	}
}

.top-box {
	display: flex;
	align-items: center;
	width: 100%;

	.company-logo {
		margin-right: 1.1875rem;
	}

	.status-badge {
		height: 1.5625rem;
		border-radius: 0.25rem;
		font-size: var(--fz-12px);
		line-height: 1.125rem;
		display: inline-flex;
		align-items: center;
		color: var(--badge-color);
		padding: 0.1875rem 0.375rem;
		margin-bottom: 0.25rem;

		&.danger {
			background-color: var(--danger-lite-color);
		}
		&.success {
			background-color: var(--success-color);
		}
		&.warning {
			background-color: var(--warning-color);
		}
	}
}

.company-logo {
	width: 3rem;
	min-width: 3rem;
	height: 3rem;
	min-height: 3rem;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	border: 2px solid var(--body-color);

	img {
		width: 100%;
	}
}

.ellipsis-dropdown {
	.dropdown-menu {
		left: -7.75rem !important;

		&:after {
			content: '';
			position: absolute;
			top: -0.5rem;
			right: 0.85rem;
			width: 1.0625rem;
			height: 1.0625rem;
			border-radius: 0.1875rem;
			box-shadow: 0px 0px 0.75rem rgba(0, 0, 0, 0.1) !important;
			transform: rotate(45deg);
			background-color: #fff;
			z-index: 1;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0rem;
			right: 0.25rem;
			width: 2.0625rem;
			height: 1.0625rem;
			background-color: #fff;
			z-index: 10;
		}
	}
}

.tenant-content-body {
	height: calc(100% - 8.6rem);

	.tenant-box-container {
		height: 100%;

		.tenent-wrapper {
			height: 100%;
			overflow-y: auto;
			padding-right: 0.75rem;
			.dropdown-menu {
				width: 10rem;
			}
		}
	}
}
.tenant-content-body,
.pagination-content-body {
	.k-pager {
		border: 0;
		padding: 0;
		background-color: transparent;
		justify-content: flex-end;
		margin-top: 1.875rem;

		.k-pager-sizes,
		.k-pager-first,
		.k-pager-last {
			display: none;
		}

		.k-pager-info {
			display: none;
		}

		.k-pager-numbers-wrap {
			.k-button {
				width: 1.75rem;
				height: 1.75rem;
				background-color: var(--grid-pg-btn-bg) !important;
				border: 1px solid var(--grid-pg-btn-border) !important;
				border-radius: 0.0625rem;
				margin-left: 0.625rem;
			}

			.k-pager-numbers {
				.k-button {
					color: var(--grid-pg-btn-color) !important;

					&.k-selected {
						border-radius: 0.125rem;
						background-color: var(--grid-pg-btn-bg) !important;
						color: var(--theme-color) !important;

						&:before {
							background: var(--warning-color) !important;
							opacity: 0.15;
							border-radius: 0.125rem;
						}
					}
				}
			}
		}
	}
}
.pagination-content-body {
	height: calc(100% - 8.6rem);
	.pagination-box-container {
		padding: 1.1875rem 1.25rem 1.1875rem 1.875rem;
		height: 100%;
		overflow-y: auto;
		&.overflow-y-visible {
			overflow-y: visible;
			padding-right: 1rem;
		}
	}
}

/*-------------------Teanant Page Styles End----------------------------*/

/*-------------------Products Page Styles Start----------------------------*/

.form-section-title {
	font-weight: 700;
	font-size: var(--fz-14px);
	line-height: 1.3125rem;
	color: var(--theme-dark-color);
	margin-top: 0.25rem;
	&.info-title > div {
		display: inline-flex;
	}
	.info-icon {
		margin-left: 0.25rem;
	}
}

.product-form-wrapper {
	padding: 1.25rem 1.875rem;
	border-radius: 0.625rem;

	.section-wrapper {
		padding: 0.9375rem 1.9375rem;
		background-color: rgba(var(--body-color-rgb), 0.4);
		border-radius: 0.375rem;
		margin-bottom: 0.5rem;

		.bottom-btn-wrapper {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.btn {
				margin-left: 0.625rem;

				&.btn-sm {
					height: 1.8125rem;
					padding: 0.25rem 0.9375rem !important;
					font-size: var(--fz-10px) !important;
					line-height: 1.25rem;
					font-weight: 600 !important;
					svg {
						margin-right: 0.25rem;
					}
				}
			}
		}

		.upload-wrapper {
			background-color: rgba(var(--info-color-rgb), 0.15);
			height: 5.625rem;
			border-radius: 0.438rem;
		}
	}

	.default-form-style {
		.form-group {
			.form-control.text-area {
				&.two-row-height {
					height: 8.7rem;
					resize: none;
				}
			}
		}
	}
}
.upload-leaf-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	overflow: hidden;
	.leaf-label {
		min-width: 8.25rem;
		margin-bottom: 0 !important;
		&.prn-label {
			min-width: 6.25rem;
		}
	}
	.fileName-wrapper {
		max-width: 100%;
		overflow: hidden;
		display: flex;
		align-items: center;
		.fileName {
			max-width: calc(100% - 1rem);
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	&.product-version{
		.fileName-wrapper{
			width: 100%;
			justify-content: center;
		}
	}
}

.chipset-disabled-wrapper{
	background-color: var(--body-color) !important;
	border: 1px solid var(--border-color-secondary);
    border-radius: 0.3125rem;
    padding: 0.5125rem 1rem;
    font-weight: 600;
    font-size: var(--fz-14px);
    line-height: 1.3125rem;
    color: var(--theme-dark-color);
    height: 5rem;
    font-family: "Gotham-Book", sans-serif !important;
	overflow-y: auto;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	.chip-wrapper{
		background-color: var(--theme-dark-color) !important;
		color: var(--theme-light-color);
		padding: 0.25rem 1.75rem 0.125rem 0.5rem;
		position: relative;
		margin: 0 0.5rem 0.25rem 0;
		display: inline-flex;
		align-items: center;
    	border-radius: 1.5rem;
		opacity: 0.7;
		font-size: var(--fz-12px);
		font-weight: 400;
		height: 1.5rem;
		.chip-lbl{
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 25rem;
		}
		&:after{
			position: absolute;
			content: "\e11c";
			font-family: "WebComponentsIcons" !important;
			right: 0.5rem;
			font-size: var(--fz-14px);
		}
	}
}

/*-------------------Products Page Styles End----------------------------*/

/*-------------------Dashboard Page Styles Start----------------------------*/

.dashboard-body {
	height: calc(100% - 4.4375rem);
	overflow-y: auto;
	.view-all {
		font-size: var(--fz-12px) !important;
		font-weight: 600;
		line-height: 1.125rem;
		text-decoration-line: underline;
		color: var(--primary-color);
		cursor: pointer;
		margin-right: 0.5rem;
		padding: 0 !important;
		background-color: transparent !important;
		border: 0 !important;
		box-shadow: none !important;
		outline: none !important;
		border-radius: 0 !important;
		white-space: nowrap;
		text-transform: unset !important;
		&:hover {
			color: var(--primary-color);
		}
	}
	.dsb-left-wrapper {
		width: 100%;
		display: flex;
		gap: 1.5rem;
	}
	.dsb-left {
		gap: 1.5rem;
		display: flex;
		flex-direction: column;
		width: calc(100% - 26rem);
		.dsb-left-box1 {
			.dsb-summary-wrapper {
				width: 100%;
				display: flex;
				gap: 1.5rem;
				.dsb-summary-left {
					width: calc(100% - 16.5rem);
					border-radius: 0.625rem;
					min-height: 20.75rem;
					overflow: hidden;
					.gmnoprint {
						display: none;
					}
				}
				.dsb-summary-right {
					max-width: 17rem;
					width: 100%;
					min-height: 20.75rem;
				}
			}
		}
		.dsb-left-box2 {
			.top-product-header {
				.default-form-style {
					display: flex;
					gap: 1.25rem;
					align-items: center;
					.form-group {
						padding-bottom: 0;
						.default-select-dropdown {
							min-width: 10.313rem;
						}
						.k-input-value-text {
							color: var(--theme-text-color-secondary);
							font-size: var(--fz-14px);
							font-weight: 500;
						}
					}
				}
			}
			.mleft-calender {
				margin-left: 1.188rem;
			}
		}
		.no-data {
			height: calc(100% - 3.5rem);
			.no-data-body {
				max-width: 14.875rem;
				max-height: 14.875rem;
				min-height: 14.875rem;
				.no-data-inner {
					max-width: 14.875rem;
					max-height: 14.875rem;
					min-height: 14.875rem;
					img {
						width: 8rem;
					}
				}
				h1 {
					font-size: var(--fz-20px);
					margin-top: 1rem;
				}
				&:after {
					max-width: 14.875rem;
					max-height: 14.875rem;
					min-height: 14.875rem;
				}
			}
		}
	}
	.dsb-right {
		gap: 1.5rem;
		display: flex;
		flex-direction: column;
		width: 24.5rem;
		.dashboard-container {
			padding: 1.1875rem 1.375rem 1.875rem 1.875rem;
			.no-data {
				height: calc(100% - 3.5rem);
				.no-data-body {
					max-width: 14.875rem;
					max-height: 14.875rem;
					min-height: 14.875rem;
					.no-data-inner {
						max-width: 14.875rem;
						max-height: 14.875rem;
						min-height: 14.875rem;
						img {
							width: 5rem;
						}
					}
					h1 {
						font-size: var(--fz-16px);
						margin-top: 1rem;
					}
					&:after {
						max-width: 14.875rem;
						max-height: 14.875rem;
						min-height: 14.875rem;
					}
				}
			}
			.dummy-skelton {
				height: calc(100% - 2rem);
			}
		}
	}
	.dashboard-container {
		padding: 1.1875rem 1.875rem;

		&.scrollContainer {
			padding-right: 1.25rem;
		}
		&.dsb-left-box2 {
			min-height: 20.75rem;
			.dummy-skelton {
				height: calc(100% - 3rem);
			}
			&.scrollContainer{
				.dummy-skelton {
					min-height: 28.65rem;
				}
			}
		}

		.page-header {
			padding: 0rem 0rem 0.75rem 0rem !important;
		}

		/*-----------Summary Styles Start-----------*/

		.widget-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0.9375rem;
			h3 {
				font-size: var(--fz-20px);
				line-height: 1.875rem;
				font-weight: 600;
				color: var(--theme-text-color-primary);
				margin-bottom: 0;
				gap: 0.5rem;
				display: inline-flex;
			}
			.default-date-ranger-combo-box {
				max-width: 15rem;
			}
		}
		.map-wrapper {
			width: 100%;
			height: 100%;
			border-radius: 0.625rem;
			// image not using anywhere - image removed 
			// background-image: url(../images/map-dummy.svg);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}

		.scan-summary-details {
			list-style: none;
			padding: 1.625rem 1.375rem !important;
			margin: 0;
			background-color: var(--theme-light-color);
			// opacity: 0.9;
			border: 1px solid var(--border-color-tertiary);
			border-radius: 0.3125rem;
			height: 100%;
			.title-text {
				display: block;
				font-weight: 400;
				font-size: var(--fz-14px);
				line-height: 1.3125rem;
				color: var(--theme-text-color-secondary);
			}
			.tilte-value {
				display: block;
				font-weight: 600;
				font-size: var(--fz-28px);
				line-height: 2.25rem;
				color: var(--theme-color);
			}
			.total-scanning {
				padding-bottom: 0.8125rem;
			}
			.most-scanned-country {
				padding-top: 0.9375rem;
				border-top: 1px solid var(--grid-pg-btn-border);
				.tilte-value {
					font-size: var(--fz-20px);
				}
			}
			.medicine-list {
				.title-medicine {
					display: block;
					font-weight: 400;
					font-size: var(--fz-12px);
					line-height: 1.625rem;
					color: var(--theme-text-color-secondary);
				}
			}
		}
		/*-----------Summary Styles End-----------*/

		/*-----------Card List Styles Start-----------*/

		.dsb-card-wrapper {
			max-height: 39.6rem;
			height: 100%;
			list-style-type: none;
			padding-left: 0;
			overflow-y: auto;
			padding-right: 0.5rem;
			margin-bottom: 0;

			li {
				min-height: 5.375rem;
				background: var(--theme-light-color);
				border: 1px solid var(--border-color-tertiary);
				border-radius: 0.313rem;
				margin-bottom: 1.25rem;
				padding: 0.813rem 1.25rem 1rem 1.25rem;
				&.min-h-auto {
					min-height: auto;
				}
				.dsb-list-top-product {
					border-bottom: unset !important;
				}

				.dsb-list-top-product {
					border-bottom: 1px solid var(--grid-pg-btn-border);
				}
				p,
				.description {
					font-weight: 700;
					font-size: var(--fz-14px);
					line-height: 1.313rem;
					color: var(--theme-text-color-secondary);
					margin-bottom: 0.938rem;
					word-break: break-word;
				}
				.dsb-date-container {
					padding-bottom: 1rem;
					display: flex;
					justify-content: flex-start;
				}
				.dsb-date-wrap {
					display: flex;
					flex-direction: column;
					padding-right: 1.188rem;
					.dsb-lbl {
						font-weight: 400;
						font-size: var(--fz-12px);
						line-height: 1.125rem;
						display: flex;
						align-items: center;
						color: var(--grid-pg-btn-color);
						margin-bottom: 0.25rem;
					}
					.dsb-value-lbl {
						font-weight: 600;
						font-size: var(--fz-14px);
						line-height: 1.313;
						display: flex;
						align-items: center;
						color: var(--theme-text-color-secondary);
					}
				}

				.dsb-list-bottom {
					display: flex;
					justify-content: flex-end;
					padding-top: 0.813rem;
					border-top: 1px solid var(--border-color-primary);
					.dsb-btn {
						font-weight: 600;
						font-size: var(--fz-14px);
						line-height: 1.138rem;
						display: flex;
						align-items: center;
						cursor: pointer;
						padding: 0 !important;
						background-color: transparent !important;
						border: 0 !important;
						&.reject {
							color: var(--dsb-reject-btn);
							margin-left: 1.75rem;
						}
						&.review {
							color: var(--warning-color);
							margin-left: 1.75rem;
						}
						&.approve {
							color: var(--success-color);
							margin-left: 1.75rem;
						}
						.loader-danger,
						.loader-primary,
						.loader-success,
						.loader-warning {
							min-width: 2.5rem;
						}
					}
				}
				&:last-child {
					margin-bottom: 0.25rem;
				}
				&.list-style-2 {
					p {
						margin-bottom: 0.5625rem;
					}
					.dsb-date-wrap {
						.dsb-value-lbl {
							font-weight: 400;
							.icon {
								color: var(--grid-pg-btn-color);
							}
						}
					}
				}
			}
		}

		/*-----------Card List Styles End-----------*/

		.coming-soon {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.typewriter {
			width: 34rem;
			font-size: var(--fz-40px);
			color: var(--dashboard-msg);
			font-weight: 600;
			white-space: nowrap;
			overflow: hidden;
			border-right: 1px solid var(--dashboard-msg);
			animation: cursor 1s step-start infinite, text 5s steps(22) alternate infinite;
		}

		@keyframes cursor {
			0%,
			100% {
				border-color: var(--dashboard-msg);
			}
		}

		@keyframes text {
			0% {
				width: 0;
			}
			100% {
				width: 34rem;
			}
		}
	}
}
.chart-tooltip {
	transition-duration: 0ms !important;
	.k-popup {
		border-radius: 0.375rem;
		border: 0;
		background-color: transparent !important;
		transition-duration: 0ms !important;
		span {
			background-color: var(--theme-dark-color);
			color: var(--theme-light-color);
			border-radius: 0.375rem;
			border: 0;
			font-size: var(--fz-14px);
			padding: 0.5rem 0.75rem;
		}
	}
}
.k-chart.k-widget {
	text {
		font-size: var(--fz-12px) !important;
	}
}
/*-----------Inline Card List Styles Start-----------*/
.inline-list-card {
	list-style: none;
	padding: 0;
	li {
		background: var(--theme-light-color);
		border: 1px solid var(--border-color-tertiary);
		padding: 1.125rem;
		border-radius: 0.25rem;
		margin-bottom: 0.625rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.list-title {
			font-size: var(--fz-14px);
			line-height: 1.25rem;
			color: var(--theme-text-color-secondary);
			padding-right: 2.938rem;
		}
		.right-box {
			display: flex;
			align-items: center;
			gap: 1rem;
			.date-location-container {
				display: flex;
				gap: 1rem;
			}
		}

		.date-box,
		.location-box {
			display: flex;
			flex-direction: column;
			padding-right: 1.188rem;
			min-width: 15rem;
			.dsb-lbl {
				font-weight: 400;
				font-size: var(--fz-12px);
				line-height: 1.125rem;
				display: flex;
				align-items: center;
				color: var(--grid-pg-btn-color);
				margin-bottom: 0.25rem;
			}
			.dsb-value-lbl {
				font-weight: 600;
				font-size: var(--fz-14px);
				line-height: 1.313;
				display: flex;
				align-items: center;
				color: var(--theme-text-color-secondary);
			}
		}
		.dsb-list-top-product {
			border-bottom: unset !important;
		}
		// .dsb-list-top,
		.dsb-list-top-product {
			border-bottom: 1px solid var(--border-color-primary);
		}
		p,
		.description {
			font-weight: 700;
			font-size: var(--fz-14px);
			line-height: 1.313rem;
			color: var(--theme-text-color-secondary);
			margin-bottom: 0.938rem;
			word-break: break-word;
		}
		.dsb-date-container {
			padding-bottom: 1rem;
			display: flex;
			justify-content: flex-start;
		}
		.dsb-date-wrap {
			display: flex;
			flex-direction: column;
			padding-right: 1.188rem;
			.dsb-lbl {
				font-weight: 400;
				font-size: var(--fz-12px);
				line-height: 1.125rem;
				display: flex;
				align-items: center;
				color: var(--grid-pg-btn-color);
				margin-bottom: 0.25rem;
			}
			.dsb-value-lbl {
				font-weight: 600;
				font-size: var(--fz-14px);
				line-height: 1.313;
				display: flex;
				align-items: center;
				color: var(--theme-text-color-secondary);
			}
		}
		.dsb-list-bottom {
			display: flex;
			justify-content: flex-end;
			padding-top: 0.813rem;
			width: 100%;
			border-top: 1px solid var(--border-color-primary);
			.dsb-btn {
				font-weight: 600;
				font-size: var(--fz-14px);
				line-height: 1.138rem;
				display: flex;
				align-items: center;
				cursor: pointer;
				padding: 0 !important;
				background-color: transparent !important;
				border: 0 !important;
				&.reject {
					color: var(--dsb-reject-btn);
					margin-left: 1.75rem;
				}
				&.review {
					color: var(--warning-color);
					margin-left: 1.75rem;
				}
				&.approve {
					color: var(--success-color);
					margin-left: 1.75rem;
				}
			}
		}
	}
	&.box-card-type-3 {
		display: flex;
		flex-wrap: wrap;
		gap: 1.5rem !important;
		padding-right: 0.5rem;
		flex-direction: row !important;
		li {
			width: 100%;
			max-width: calc((100% / 4) - 1.15rem);
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 0;
			.list-title {
				padding-right: 0;
				font-weight: 700;
				font-size: var(--fz-14px);
				line-height: 1.313rem;
				color: var(--theme-text-color-secondary);
				margin-bottom: 0.938rem;
			}
			.right-box {
				width: 100%;
				flex-wrap: wrap;
				flex-direction: column;
				align-items: flex-start;
				.date-location-container {
					display: flex;
					gap: 1.5rem;
					width: 100%;
				}
				.date-box,
				.location-box {
					min-width: unset !important;
				}
				.button-box {
					width: 100%;
					border-top: 1px solid var(--grid-pg-btn-border);
					display: flex;
					justify-content: flex-end;
					padding-top: 0.813rem;
				}
			}
		}
	}
	&.file-failed-list {
		height: calc(100% - 3rem) !important;
		overflow-y: auto;
		padding-right: 0.5rem;
		li {
			.list-title {
				padding-right: 0;
				font-weight: 700;
				font-size: var(--fz-13px);
			}
		}
	}
}

.pagination-box-container {
	.inline-list-card {
		&.file-failed-list {
			height: 100% !important;
		}
	}
}

/*-----------Inline Card List Styles End-----------*/

/*-----------Notification Styles Start-----------*/

.notification-list-card-wrapper {
	height: 100%;
	max-height: calc(100% - 3rem) !important;
	overflow-y: auto;
	gap: 0.625rem;
	display: flex;
	width: 100%;
	flex-direction: column;
	padding-right: 0.5rem;
	.no-notification {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.notifictaion-item {
		background: var(--theme-light-color);
		border: 1px solid var(--border-color-tertiary);
		padding: 1.125rem;
		border-radius: 0.25rem;
		display: flex;
		gap: 1.25rem;
		align-items: flex-start;
		flex-direction: column;
		max-width: 100%;
		cursor: default;
		.list-title {
			display: flex;
			gap: 1rem;
			font-size: var(--fz-12px);
			line-height: 1.25rem;
			font-weight: 700;
			width: 100%;
			max-width: calc(100% - 30rem);
			word-break: break-word;
			color: var(--theme-text-color-secondary);
			b {
				color: var(--theme-text-color-primary);
			}
			.icon {
				height: 3.625rem;
				width: 3.625rem;
				min-width: 3.625rem;
				border-radius: 1.8125rem;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border: 1px solid var(--disabled-color);
				color: var(--theme-text-color-secondary);
				font-size: var(--fz-21px);
				cursor: pointer;
			}
		}
		.right-box {
			flex-direction: row;
			flex-wrap: nowrap;
			display: flex;
			width: 100%;
			gap: 1.25rem;
			font-weight: 700;
			max-width: 30rem;
			.date-location-container {
				flex-wrap: wrap;
				gap: 0.3125rem;
				display: flex;
				width: 100%;
			}
			.date-box,
			.location-box {
				display: flex;
				flex-direction: row;
				gap: 0.25rem;
				margin-right: 1rem;
				.dsb-lbl,
				.dsb-value-lbl {
					font-size: var(--fz-12px);
					line-height: 1.125rem;
					margin-bottom: 0.25rem;
				}
				.dsb-lbl {
					color: var(--theme-text-color-secondary);
					font-weight: 400;
				}
				.dsb-value-lbl {
					color: var(--theme-text-color-primary);
					font-weight: 700;
				}
			}
			.read-checker {
				color: var(--primary-color);
			}
		}
		&.read {
			background-color: var(--notification-read);
			border-color: var(--notification-read);
			.list-title {
				color: var(--secondary-color);
				b {
					color: var(--secondary-color);
				}
				.icon {
					background-color: var(--light-color);
					border-color: var(--light-color);
					color: var(--secondary-color);
				}
			}
			.right-box {
				.read-checker {
					color: var(--secondary-color);
				}
				.date-box,
				.location-box {
					.dsb-lbl,
					.dsb-value-lbl {
						color: var(--secondary-color);
					}
				}
			}
		}
	}
	.inline-list-card {
		display: flex;
		width: 100%;
		flex-direction: column;
		gap: 0.625rem;
		.notifictaion-item {
			align-items: center;
			flex-direction: row;
			.list-title {
				align-items: center;
			}
			.right-box {
				align-items: center;
				justify-content: flex-end;
				.date-location-container {
					width: 100%;
					justify-content: flex-end;
					gap: 1.5rem;
				}
				.date-box,
				.location-box {
					margin-right: 0;
					gap: 0.3125rem;
					width: auto;
					min-width: 5rem;
					flex-direction: column;
					.dsb-lbl,
					.dsb-value-lbl {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
.tenant-search {
	&.notification-search-form {
		max-width: 50rem;
		.mark-all-read {
			font-size: var(--fz-12px) !important;
			font-weight: 700;
			line-height: 1.125rem;
			text-decoration-line: underline;
			color: var(--primary-color);
			cursor: pointer;
			margin-left: 1rem;
			margin-right: 0.5rem;
			padding: 0 !important;
			background-color: transparent !important;
			border: 0 !important;
			box-shadow: none !important;
			outline: none !important;
			border-radius: 0 !important;
			white-space: nowrap;
			svg {
				margin-right: 0.5rem;
			}
		}
	}
}
.box-container {
	.notification-list-card-wrapper {
		max-height: 100% !important;
	}
}

/*-----------Notification Styles End-----------*/

/*-----------Awaiting Actions Styles Start-----------*/
.awaiting-actions-header {
	.tenant-search {
		max-width: 40rem;
	}
}

.awaiting-actions-container {
	overflow-y: hidden !important;
	padding-right: 0.75rem !important;
	.box-card-type-3 {
		overflow-y: auto;
		max-height: 100%;
		// height: 100%;
	}
}

/*-----------Awaiting Actions Styles End-----------*/

/*-----------Dashboard Kendo Grid Widget Styles Start-----------*/

.dashboard-widget-grid-wrapper {
	.default-kendo-grid {
		.k-grid-pager {
			margin-top: 1rem;
			padding-right: 0.5rem;
		}
	}
}

/*-----------Dashboard Kendo Grid Widget Styles End-----------*/

/*-----------Unstyled List Styles Start-----------*/

.list-unstyled {
	li {
		font-size: var(--fz-14px);
		line-height: 1.313rem;
		color: var(--theme-text-color-secondary);
		padding: 0.75rem 0;
		word-break: break-word;
	}
	&.bold-style {
		li {
			font-weight: 700;
		}
	}
	&.with-seperator {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		li {
			border: 1px solid var(--border-color-tertiary);
			padding: 0.75rem;
			background-color: var(--body-color);
			border-radius: 0.25rem;
		}
	}
}

/*-----------Bold Unstyled List Styles End-----------*/

.k-chart-tooltip-wrapper {
	margin-top: -25px;
	.k-popup.k-child-animation-container {
		border-radius: 4px;
	}
}

/*-------------------Dashboard Page Styles End----------------------------*/

/*-------------------Add Product Group Page Styles Start----------------------------*/

.add-product-group-module {
	.page-content-body {
		height: calc(100% - 5.4375rem);
	}
}
.addGroup-box-container {
	padding: 3.25rem 0.625rem 1.25rem 0.625rem;
	height: 100%;
	.addGroup-box-container-inner {
		height: 100%;
		&.product-group-scroll {
			overflow-y: auto;
			padding-right: 0.8rem;
			.cstm-config-lbl {
				font-weight: 600;
				font-size: var(--fz-14px);
				line-height: 150%;
				color: var(--theme-text-color-secondary);
				margin-bottom: 0.1875rem;
			}
		}
	}
	.default-form-style {
		width: calc(100% - 2.2rem);
	}
	.product-description-group {
		.form-control.text-area {
			height: 4.8125rem;
			resize: none;
		}
	}
	.row-gutter-10px {
		margin-left: -0.3125rem !important;
		margin-right: -0.3125rem !important;

		.gutter-10px {
			padding-left: 0.3125rem !important;
			padding-right: 0.3125rem !important;
		}
	}
	.product-group-list-box {
		height: calc(100% - 8rem);
		position: relative;
		.section-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 1.0625rem;
			padding-right: 1rem;
			position: relative;
			h3 {
				font-size: var(--fz-14px);
				font-weight: 700;
				color: var(--theme-dark-color);
				margin: 0;
			}
			.product-group-error-msg {
				position: absolute;
				top: 0.25rem;
				right: 6rem;
				left: unset;
			}
			.btn {
				height: 1.8125rem;
				font-size: var(--fz-10px) !important;
				font-weight: 400 !important;
			}
		}
		.list-box-inner {
			display: flex;
			height: calc(100% - 1rem);
			.two-column {
				.k-listbox {
					height: calc(100% - 2.8125rem);
					width: 100%;
					.k-list-scroller {
						border: 0;
						background: transparent;
						height: 100%;
						.k-list-content {
							.k-list-ul {
								display: flex;
								flex-wrap: wrap;
								width: calc(100% + 0.58125rem);
								.k-list-item {
									padding: 0.625rem 1rem;
									background: var(--theme-light-color);
									border: 1px solid var(--light-color);
									border-radius: 0.290485625rem;
									width: calc(50% - 1rem);
									margin-right: 0.929375rem;
									margin-bottom: 0.58125rem;
									font-size: var(--fz-13px);
									color: var(--theme-text-color-secondary);
									&.k-selected {
										background-color: rgba(
											var(--warning-color-rgba),
											0.15
										) !important;
										color: var(--theme-color) !important;
									}
								}
							}
						}
					}
				}
			}
			.col-left {
				background-color: var(--body-color);
				border-radius: 0.1875rem;
				padding: 1rem 0.6875rem 1rem 1.25rem;
				width: calc(50% - 2.90625rem);
				position: relative;
				.k-listbox {
					.k-listbox-actions {
						position: absolute;
						top: 0;
						right: -4.28125rem;
						height: 100%;
						justify-content: center;
						.k-button {
							width: 2.75rem;
							height: 2.75rem;
							border-radius: 0.375rem;
							background: var(--body-color);
							border: 1px solid var(--primary-color);
							color: var(--primary-color);
							-webkit-transition: all 0.4s ease-in-out;
							-moz-transition: all 0.4s ease-in-out;
							-ms-transition: all 0.4s ease-in-out;
							-o-transition: all 0.4s ease-in-out;
							transition: all 0.4s ease-in-out;
							.k-i-caret-alt-right::before {
								content: '\e005' !important;
								font-size: var(--fz-20px);
							}
							.k-i-caret-alt-left::before {
								content: '\e007' !important;
								font-size: var(--fz-20px);
							}
							&:hover {
								background: var(--primary-color);
								color: var(--white-color);
							}
						}
					}
				}
			}
			.col-right {
				background-color: var(--body-color);
				border-radius: 0.1875rem;
				padding: 1rem 0.6875rem 1rem 1.25rem;
				width: calc(50% - 2.90625rem);
				margin-left: 5.8125rem;
				margin-top: -6.35rem;
				height: calc(100% + 6.35rem);
			}
		}
		.error-msg {
			font-size: var(--fz-14px) !important;
			font-weight: 600;
			color: var(--danger-lite-color) !important;
			position: absolute;
			bottom: -0.5rem;
			left: 0;
			margin: 0 !important;
		}
		&.style-type-2 {
			margin-top: 2.5rem !important;
			.col-right {
				height: 100%;
				margin-top: 0 !important;
			}
		}
		&.custom-product-configure {
			.list-box-inner {
				.col-right {
					margin-top: -3.35rem !important;
					height: calc(100% + 3.35rem) !important;
				}
			}
		}
	}
}

/*-------------------Add Product Group Page Styles End----------------------------*/

/*-------------------Add Product Page Styles Start----------------------------*/

.add-product-module {
	.page-content-body {
		height: calc(100% - 4.3125rem);
		.row {
			height: 100%;
			.right-column {
				height: 100%;
				.product-form-wrapper .section-wrapper {
					.no-data {
						height: calc(100vh - 25rem);
						padding: 2rem;
						.no-data-body {
							max-width: 25.875rem;
							max-height: 25.875rem;
							.no-data-inner {
								max-width: 25.875rem;
								max-height: 25.875rem;
							}
							h1 {
								font-size: var(--fz-28px);
							}
						}
					}
				}
			}
			.product-form-wrapper {
				height: 100% !important;
				padding-right: 0.75rem;
				overflow-y: unset !important;
				.default-form-style {
					height: 100%;
					position: relative;
					.form-body {
						height: calc(100% - 5rem);
						overflow-y: auto;
						.k-panelbar {
							background-color: var(--theme-bg-color) !important;
							border: 0 !important;
							.k-panelbar-header {
								border: 0 !important;
							}
							li {
								.k-link {
									background-color: var(--theme-bg-color) !important;
									.edit-icon-btn {
										color: var(--theme-text-color-primary);
									}
									.k-icon {
										color: var(--accordian-icon);
									}
								}
								.k-content {
									background-color: var(--box-container-bg);
								}
							}
						}
						.bottom-btn-wrapper {
							position: relative;
							left: unset;
							right: unset;
							bottom: unset;
							height: unset;
							display: flex;
							align-items: center;
							justify-content: flex-end;
							background-color: unset;
							padding: unset;
							.btn {
								min-width: unset;
								margin-left: 0.625rem;
							}
						}
					}
					.bottom-btn-wrapper {
						position: absolute;
						left: -1.875rem;
						right: -0.75rem;
						bottom: -1.25rem;
						height: 5.25rem;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						background-color: rgba(var(--body-color-rgb), 0.4);
						padding: 0.5rem 1.3rem;
						.btn {
							min-width: 10rem;
							margin-left: 1.25rem;
						}
					}
				}

				.prn-wrapper {
					height: calc(100% - 5rem);
					overflow-y: auto;

					.form-body {
						overflow-y: visible;
						height: auto;
					}
				}
			}
		}
	}
	.left-box {
		border-radius: 0.625rem;
		.k-panelbar {
			border: 0 !important;
			background-color: var(--theme-bg-color) !important;
			border-radius: 0.625rem;
			margin-bottom: 0.625rem !important;
			padding: 0;
			.k-panelbar-item {
				padding: 0;
				border-radius: 0.625rem;
				background-color: var(--theme-bg-color) !important;
				z-index: 9;
				position: relative;
				.k-content {
					background-color: var(--theme-bg-color) !important;
				}
				.k-link {
					background-color: var(--theme-bg-color) !important;
					padding: 0 1.25rem 0 0;
					border-radius: 0.625rem;
					position: relative;
					font-size: var(--fz-16px);
					font-weight: 600;
					line-height: 1.3125rem;
					display: flex;
					align-items: center;
					color: var(--theme-dark-color) !important;
					cursor: pointer;
					.panel-link-btn {
						margin-bottom: 0;
					}
					.k-icon {
						color: var(--accordian-icon);
					}
					.panel-name {
						position: relative;
						padding-left: 2rem;
						&:before {
							position: absolute;
							content: '';
							left: 0;
							width: 1.25rem;
							min-width: 1.25rem;
							height: 1.25rem;
							border-radius: 0.625rem;
							background-color: var(--white-color);
							border: 2px solid var(--theme-canvas-title-border);
						}
						&.active {
							&:before {
								background-color: var(--info-lite-color);
								border: 2px solid var(--info-color);
							}
						}
						&.completed {
							color: var(--black-color) !important;
							&:before {
								background-color: var(--success-color);
								border: 2px solid var(--success-color);
							}
							&:after {
								position: absolute;
								content: '';
								left: 0.45rem;
								top: 0.25rem;
								width: 0.35rem;
								height: 0.65rem;
								border: solid var(--white-color);
								border-width: 0 0.125rem 0.125rem 0;
								transform: rotate(45deg);
							}
						}
					}
				}
				.k-child-animation-container {
					padding: 0rem 1.25rem 1.25rem 3.25rem;
					.k-panelbar-content {
						padding: 0;
						.panel-btn-group {
							display: inline-flex;
							&.vertical {
								flex-direction: column;
							}
							.btn {
								width: auto !important;
								min-width: 11.4375rem;
								height: 2.1875rem;
								padding: 0.5rem 1rem !important;
								display: inline-flex;
								align-items: center;
								justify-content: flex-start;
								font-size: var(--fz-12px) !important;
								line-height: 0.75rem;
								color: var(--theme-text-color-primary);
								border-radius: 0.3125rem !important;
								&.active {
									background-color: rgba(var(--theme-color-rgb), 0.07);
								}
							}
						}
					}
				}
			}
		}
	}
	.panel-link-btn {
		background-color: var(--theme-bg-color) !important;
		padding: 1.25rem 1.25rem 1.25rem 3.25rem;
		border-radius: 0.625rem;
		margin-bottom: 0.625rem;
		position: relative;
		font-size: var(--fz-16px);
		font-weight: 600;
		line-height: 1.3125rem;
		display: flex;
		align-items: center;
		color: var(--grid-pg-btn-color) !important;
		&:before {
			position: absolute;
			content: '';
			left: 1.25rem;
			width: 1.25rem;
			min-width: 1.25rem;
			height: 1.25rem;
			border-radius: 0.625rem;
			border: 2px solid var(--light-color);
		}
		&.active {
			color: var(--theme-dark-color) !important;
			&:before {
				background-color: var(--info-lite-color);
				border: 2px solid var(--info-color);
			}
		}
		&.completed {
			color: var(--theme-black-text-color) !important;
			&:before {
				background-color: var(--success-color);
				border: 2px solid var(--success-color);
			}
			&:after {
				position: absolute;
				content: '';
				left: 1.7rem;
				top: 1.55rem;
				width: 0.35rem;
				height: 0.65rem;
				border: solid var(--white-color);
				border-width: 0 0.125rem 0.125rem 0;
				transform: rotate(45deg);
			}
		}
	}
	.image-wrapper {
		width: 100%;
		min-width: 6.25rem;
		max-width: 6.25rem;
		height: 100%;
		min-height: 6.25rem;
		max-height: 6.25rem;
		margin: 1rem auto;
		position: relative;
		border: 1px solid var(--border-color-secondary);

		.crop-image {
			width: calc(6.25rem - 2px);
			height: calc(6.25rem - 2px);
		}

		.fa-xmark {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}

th.k-header.active .k-grid-column-menu {
	color: var(--theme-color) !important;
}
.web-link-group {
	z-index: 99 !important;
	position: relative;
}

.default-download-product-file {
	background-color: var(--file-upload-bg);
	min-height: 3.25rem;
	border: 1px solid var(--file-upload-border-color);
	border-radius: 0.313rem;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 0.625rem;
	color: var(--theme-text-color-secondary);
	cursor: pointer;
	.download-file-wrap {
		display: flex;
		justify-content: space-between;
		.download-lbl-wrap {
			display: flex;
			svg {
				color: var(--file-color);
			}
			.mfg-value-lbl {
				margin-left: 1rem;
				font-weight: 700;
			}
		}
	}
}
/*-------------------Add Product Page Styles End----------------------------*/

/*-------------------common search Styles Start----------------------------*/

.default-kendoGrid-toolbar {
	border: 0;
	.search-box {
		margin-left: auto;
		display: flex;
		align-items: center;
		max-width: 25rem;
		width: 100%;
		padding-bottom: 0;
		.form-label {
			margin-bottom: 0;
			margin-right: 0.25rem;
		}
		.form-control {
			width: 100%;
		}
	}
}
/*-------------------common search Styles End----------------------------*/

/*-------------------L3 System Page Styles Start----------------------------*/
.create-l3-box-container {
	padding: 1.1875rem 1rem 1.1875rem 1.875rem;
	height: 100%;
	.default-form-style {
		overflow-y: auto;
		height: calc(100% - 4rem);
		padding-right: 0.75rem;
	}
	.connection-method-row {
		margin-top: 2.6rem;
	}
	.two-row-height {
		min-height: 8.7rem;
	}
	.btn-footer-wrapper {
		display: flex;
		justify-content: end;
		align-items: center;
		height: 3.5rem;
		margin-top: 0.5rem;
		padding-right: 1.25rem;
	}
}

/*-------------------L3 System Page Styles End----------------------------*/
.editable-tooltip-warpper {
	width: 10rem;
	display: flex;
	align-items: center;
	.editable-tooltip-input {
		max-width: 7rem;
		height: 1.75rem;
		border-radius: 0.25rem;
		font-size: 0.75rem !important;
		padding: 0.25rem !important;
	}
	.btn.btn-save {
		height: 1.75rem;
		margin-left: 0.25rem;
		padding: 0.5rem !important;
		font-size: 0.75rem !important;
		display: inline-flex;
		align-items: center;
	}
}

/*-------------------Product Order Management Page Styles Start----------------------------*/

.web-link-downloader-wrapper {
	display: flex;
	align-items: center;
	.radioBtnGroupr {
		display: flex;
		align-items: center;
		.form-control-radio-btn {
			margin-right: 1rem;
		}
	}
}
.offcanvas-weblink-download {
	padding-bottom: 0 !important;
	.web-link-downloader-wrapper {
		flex-direction: column;
		align-items: flex-start;
		.radioBtnGroupr {
			justify-content: space-between;
			width: 100%;
			gap: 1rem;
			.form-control-radio-btn {
				width: 100%;
				margin-right: 0 !important;
			}
		}
		.btn-primary {
			width: 100%;
			margin-top: 1rem;
		}
	}
}
/*-------------------Product Order Management Page Styles End----------------------------*/
/*-------------------Product Order Details Styles Start----------------------------*/
.product-order-details-container {
	position: relative;
	padding: 1.25rem 1.875rem;
	border-radius: 0.625rem;
	height: 100% !important;
	padding-right: 0.75rem;
	overflow-y: unset !important;
	&.weblink-flush{
		padding-right: 1.875rem;
	}
	form {
		height: calc(100% - 5rem);
		overflow-y: auto;
		padding-right: 0.75rem;
		&.weblink-report-form {
			overflow-y: auto !important;
			.weblink-report-grid {
				height: calc(100vh - 26rem) !important;
				min-height: 24rem;
				&.end-user-report-grid {
					height: calc(100vh - 34rem) !important;
					min-height: 24rem;
				}
			}
		}
	}
	.bottom-btn-wrapper {
		height: 5.25rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-color: rgba(var(--body-color-rgb), 0.4);
		padding: 0.5rem 1.3rem;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		.k-button,
		.btn {
			max-width: 10rem;
			width: 100%;
		}
	}
}

/*-------------------Product Order Details Styles End----------------------------*/
/*-------------------WebLink Page Details Styles Start----------------------------*/
.weblink-box-container {
	position: relative;
	padding: 1.25rem 1.875rem;
	border-radius: 0.625rem;
	height: 100% !important;
	padding-right: 0.75rem;
	overflow-y: unset !important;
	.weblink-row {
		height: 100%;
		.weblink-left-box {
			height: 100%;
		}
		.weblink-right-box {
			height: 100%;
			.right-scroll-wrapper {
				padding: 1rem;
				background-color: var(--body-color);
				height: 100%;
				.tenant-content-body {
					height: 100%;
					overflow-y: auto;
					.po-weblink-wrap {
						width: 100%;
						height: 100%;
						background-color: var(--body-color);
					}
					.form-group {
						padding-bottom: 1rem !important;
						& > span {
							display: flex !important;
							.k-checkbox-label {
								font-size: var(--fz-14px);
								color: var(--theme-dark-color);
								word-break: break-word;
								line-height: 1rem;
							}
						}
					}
				}
			}
		}
	}

	form {
		height: calc(100% - 5rem);
		padding-right: 0.75rem;
		.default-weblink-footer {
			height: 5.25rem;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			background-color: rgba(var(--body-color-rgb), 0.4);
			padding: 0.5rem 1.3rem;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			.k-button,
			.btn {
				max-width: 12rem;
				width: 100%;
			}
		}
	}
}
.weblink-management-container {
	padding-bottom: 5rem !important;
	.default-kendo-grid {
		height: auto !important;
		margin-bottom: 1rem;
	}
	// .k-grid-content.k-virtual-content{
	// 	height: calc(100vh - 40rem);
	// }
	.bottom-btn-wrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		display: flex;
		justify-content: flex-end;
		background:rgba(var(--body-color-rgb),0.75);
		padding: 1.1875rem 1.25rem 1.1875rem 1.875rem;
	}
	.default-kendo-grid {
		&.audit-report-grid {
			height: calc(100vh - 38rem) !important;
			overflow-y: auto;
			min-height: 20rem !important;
		}
	}
}
.weblink-management-container{
	position: relative;
	.weblink-retrieval {
		position: relative;
		margin-top: -4.3rem;
		left: 50%;
		max-width: 12rem;
		&.weblink-retrieval-hide{
			margin-top: -2.3rem;
		}
	}
	.weblink-retrieval-valid {
		position: relative;
		margin-top: -1rem;
	}
}

.weblink-url{
	font-weight: 600;
	margin-bottom: 1rem;
	text-decoration: underline;
	word-break: break-word;
	border: 1px solid var(--border-color-secondary);
    border-radius: 0.3125rem;
    padding: 0.5125rem 1rem;
    font-weight: 600;
    font-size: var(--fz-14px);
    line-height: 1.3125rem;
    color: var(--theme-dark-color);
	background-color: var(--body-color) !important;
    cursor: not-allowed !important;
}



/*-------------------WebLink Page Details Styles End----------------------------*/

/*-------------------OTP Styles Start----------------------------*/
// .otp-group {
// 	display: flex;
// 	width: 100%;
// 	max-width: 360px;
// 	column-gap: 10px;
// }

// .otp-input {
// 	width: 100%;
// 	height: 60px;
// 	border: 1px solid #ccc;
// 	border-radius: 5px;
// 	text-align: center;
// 	font-size: 32px;
// 	font-weight: bold;
// 	line-height: 1;
// }

/*-------------------OTP Styles End----------------------------*/

/*-------------------Footer-Box-Container Styles Start----------------------------*/

.footer-box-container {
	position: relative;
	padding: 1.25rem 1.875rem;
	border-radius: 0.625rem;
	height: 100% !important;
	padding-right: 0.75rem;
	overflow-y: unset !important;
	form {
		height: calc(100% - 5rem);
		overflow-y: auto;
		padding-right: 0.75rem;
	}
	.bottom-btn-wrapper {
		height: 5.25rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-color: rgba(var(--body-color-rgb), 0.4);
		padding: 0.5rem 1.3rem;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		.btn {
			max-width: 10rem;
			width: 100%;
		}
	}
}

/*-------------------Footer-Box-Container Styles End----------------------------*/

/*-------------------PO RETRIEVAL Styles Start----------------------------*/

.po-retrieval-form {
	overflow-y: visible !important;
	padding-right: 0 !important;
	.form-group {
		height: 100%;
		padding-bottom: 0;
		.error-msg {
			right: 0.75rem;
		}
	}
}
.po-retrieval-group {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	width: 100%;
	gap: 1rem;
	flex-direction: column;
	height: calc(100% - 1rem);
	overflow-y: auto;
	padding-right: 0.75rem;
	.form-control-radio-btn {
		max-width: 100%;
		min-width: 25rem;
	}
}

/*-------------------PO RETRIEVAL Styles End----------------------------*/

/*---------------Scanner Products desktop design Starts here---------------*/
.dark-theme {
	.scanned-products-wrapper {
		background: linear-gradient(
			180deg,
			rgba(217, 217, 217, 0) 41.78%,
			rgba(217, 217, 217, 0.114) 100%
		);
		.carousel {
			background: linear-gradient(
				180deg,
				rgba(217, 217, 217, 0) 41.78%,
				rgba(217, 217, 217, 0.114) 100%
			);
		}
	}
}
.sc-container {
	display: flex;
	// height: 100%;
	height: calc(100% - 2.75rem);
	width: 100%;
}
.first-sc-container {
	width: 40%;
}
.logo-container.scanned-products {
	width: 100%;
	max-height: 31.875rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	img {
		max-width: 6rem;
		padding: 1.125rem 0 0 1.125rem;
		width: 100%;
	}
	span {
		padding: 1.125rem 1.125rem 0 0rem;
		font-size: var(--fz-10px);
		color: var(--grid-pg-btn-color);
	}
}
.scanned-products-wrapper {
	background: linear-gradient(
		180deg,
		rgba(217, 217, 217, 0) 41.78%,
		rgba(217, 217, 217, 0.114) 100%
	);
	flex-direction: column;
	padding: 7rem 1.25rem 1.25rem;
	display: flex;
	// height: 100%;
	height: calc(100% - 5.874rem);

	.carousel {
		width: 80%;
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		min-height: 30rem;
		margin: 0 auto;
		.carousel-inner {
			height: 100%;

			.carousel-item {
				height: 100%;
				-webkit-transition: all 1s ease-in-out;
				-moz-transition: all 1s ease-in-out;
				-ms-transition: all 1s ease-in-out;
				-o-transition: all 1s ease-in-out;
				transition: all 1s ease-in-out;
				display: flex;
				justify-content: center;
				align-items: center;

				.carousel-caption {
					text-align: center;
					position: relative;
					padding: 0;
					bottom: unset;
					left: unset;
					right: unset;
				}
			}
		}

		.carousel-indicators {
			position: relative;
			margin-left: 0;
			justify-content: center;
			margin-right: 0;

			[data-bs-target] {
				width: 1.25rem;
				height: 0.25rem;
				background-color: #e7643b;
			}
		}

		.carousel-control-prev,
		.carousel-control-next {
			display: none;
		}
	}
}
.sc-container.end-user-container {
	height: 100%;
	.scanned-products-wrapper {
		height: 100%;
	}
	&.with-footer {
		height: calc(100% - 2.75rem);
	}
	&.no-tenant-logo {
		.scanned-products-wrapper {
			padding-top: 1.25rem;
		}
		.logo-container.scanned-products {
			display: none;
		}
	}
}
.scanner-products-right-wrapper {
	width: 60%;
	height: 100%;
	padding: 2rem 1.25rem 2rem 1.125rem;
	background-color: var(--box-container-bg);
	.custom-scroller {
		height: 100%;
		overflow-y: auto;
		padding-right: 1rem;
		.default-kendo-grid {
			height: auto !important;
			max-height: 100%;
			.k-grid {
				border: 0;
				height: 100% !important;
				background-color: transparent;
			}
		}
	}
	.sv-btn {
		font-size: var(--fz-12px) !important;
		line-height: 1.375rem;
		background-color: var(--success-color);
		color: var(--white-color);
		padding: 0.375rem 0.75rem !important;
		font-weight: 700 !important;
		border: 0 !important;
		border-radius: 0.375rem !important;
		height: 2rem;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 0.813rem;
		// max-width: 6.8rem;
		&.bg-success {
			background-color: var(--success-color) !important;
		}
		&.bg-danger {
			background-color: var(--danger-lite-color) !important;
		}
	}
	h3 {
		font-weight: 600;
		font-size: var(--fz-20px);
		line-height: 1.875rem;
		color: #161e34;
		padding: 0 0 0.813rem 0;
		margin-bottom: 0;
	}
	.company-name {
		color: #1268b3;
		font-weight: 700;
		font-size: var(--fz-16px); // client request to increase font weight and size
		padding-bottom: 0.813rem;
		text-decoration: underline;
		display: block;
	}
	p {
		font-weight: 400;
		font-size: var(--fz-14px);
		line-height: 1.5rem;
		color: #191f28;
		margin-bottom: 2rem;
	}
	.scanned-card-wrap {
		width: 100%;
		.scanned-products-card {
			min-height: 4.75rem;
			background: rgba(var(--custom-tile-bg-rgba), 0.63);
			border-radius: 0.313rem;
			padding: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			height: 100%;
			// margin-bottom: 0.625rem;

			.mfg-lbl {
				font-weight: 400;
				font-size: var(--fz-14px);
				line-height: 1.125rem;
				display: flex;
				align-items: center;
				color: var(--grid-file-btn-color);
				margin-bottom: 0.25rem;
			}
			.mfg-value-lbl {
				font-weight: 600;
				font-size: var(--fz-14px);
				line-height: 1.313;
				display: flex;
				align-items: flex-start;
				flex-direction: column;
				color: var(--theme-text-color-secondary);
				word-break: break-word;
			}
		}
	}
	.scan-wrap {
		display: inline-flex;
		margin-bottom: 0.688rem;
		.scan-lbl {
			font-weight: 400;
			font-size: var(--fz-14px);
			line-height: 1.125rem;
			display: flex;
			align-items: center;
			color: var(--grid-file-btn-color);
			margin-bottom: 0;
			margin-right: 0.375rem;
			color: var(--theme-text-color-secondary);
		}
		.scan-score {
			width: 1.8rem;
			height: 1.8rem;
			background: rgba(var(--count-bg-2-rgb), 0.6);
			border-radius: 0.25rem;
			color: var(--theme-text-color-primary);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: var(--fz-12px);
			font-weight: 600;
			line-height: 100%;
		}
	}
	.download-product-file {
		max-width: 49rem;
		background-color: var(--file-upload-bg);
		margin-left: 0.625rem;
		min-height: 3.25rem;
		border: 1px solid var(--file-upload-border-color);
		border-radius: 0.313rem;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 0.625rem;
		color: var(--theme-text-color-secondary);
		cursor: pointer;
		.download-file-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.download-lbl-wrap {
				display: flex;
				align-items: center;
				svg {
					color: var(--file-color);
					font-size: var(--fz-16px);
				}
				.mfg-value-lbl {
					margin-left: 1rem;
					font-weight: 700;
					font-size: var(--fz-16px);
					line-height: 1.313rem;
				}
				.fa-angle-right {
					font-size: var(--fz-16px);
				}
			}
		}
	}
	.k-grid-norecords .k-table-td {
		height: 15rem !important;
	}

	.k-grid {
		.k-grid-container {
			overflow: visible;
			&:after {
				display: none;
			}
		}
		.k-grid-content {
			overflow: visible;
			overflow-x: visible;
			overflow-y: visible;
		}
	}
	.k-grid-header,
	.k-grid-footer {
		padding-right: 0;
	}
}
.endUser-footer {
	position: fixed;
	bottom: 0;
	background-color: #fff;
	width: 100%;
	padding: 0.75rem;
	text-align: center;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
	font-weight: 400;
	font-size: var(--fz-14px);
	line-height: 1.125rem;
	color: var(--grid-file-btn-color);
	z-index: 10;
}
.scan-img-width {
	width: 100% !important;
	max-width: 100% !important;
}
.tenant-scan-logo {
	width: 100% !important;
	max-width: 6rem !important;
	position: absolute;
	top: 0;
	left: 0;
}

.end-user-container {
	.scanner-products-right-wrapper .k-grid.k-grid-virtual .k-grid-header {
		padding-right: 0.5rem !important;
	}
	.scanner-products-right-wrapper .k-grid .k-grid-container {
		overflow: hidden !important;
		height: auto !important;
		max-height: 30rem !important;
		.k-grid-content {
			overflow: auto !important;
			overflow-x: auto !important;
			overflow-y: scroll !important;
		}
	}
}
.coma-seperator{
	display: inline-flex;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 3px 5px;
    background-color: #ccc;
    border-radius: 15px;
    min-width: 3rem;
    justify-content: center;
}
.scan-wrap {
	display: inline-flex;
	margin-bottom: 0.688rem;
	.scan-lbl {
		font-weight: 400;
		font-size: var(--fz-14px);
		line-height: 1.125rem;
		display: flex;
		align-items: center;
		color: var(--grid-file-btn-color);
		margin-bottom: 0;
		margin-right: 0.375rem;
		color: var(--theme-text-color-secondary);
	}
	.scan-score {
		width: auto;
		padding: 0.5rem 0.25rem;
		height: 1.8rem;
		background: rgba(var(--count-bg-2-rgb), 0.6);
		border-radius: 0.25rem;
		color: var(--theme-text-color-primary);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: var(--fz-12px);
		font-weight: 600;
		line-height: 100%;
	}
}
/*---------------Scanner Products desktop design Ends here---------------*/

/*-----------------Generate Batch Detail Report Styles Start--------------------------*/

.batch-report-chart-grid-box {
	.default-kendo-grid.h-auto .k-grid-norecords .k-table-td {
		height: 310px !important;
	}
}
.custom-loader-spinner {
	&.type-1 {
		width: 100%;
		height: calc(100% - 5rem);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 9;
		border-radius: 0.625rem;
		background-color: rgba(255, 255, 255, 0.5);
		.k-loader-primary {
			color: var(--primary-color) !important;
		}
	}
}
// close button disabling in date picker
span.close-button.clearBtn.disabled {
	opacity: 0.5;
}
/*--------weblink scan history design-------*/
.weblink-scan-wrap {
	.scan-lbl {
		font-weight: 600;
		font-size: var(--fz-14px);
		line-height: 150%;
		color: var(--theme-text-color-secondary);
		margin-bottom: 0.1875rem;
	}
}

/*-----------------Generate Batch Detail Report Styles End--------------------------*/

/*-----------------Serail Number Management Styles Start--------------------------*/

.common-page-content {
	.page-content-body {
		height: calc(100% - 4.4375rem);
		.common-box-container {
			.top-wrapper {
				overflow-y: auto;
				height: calc(100% - 4rem);
				padding-right: 0.75rem;
			}
			.bottom-btn-wrapper {
				display: flex;
				justify-content: end;
				align-items: center;
				height: 3.5rem;
				margin-top: 0.5rem;
				padding-right: 1.25rem;
			}
		}
	}
}
.serial-number-list-container {
	.k-listview-content {
		overflow-y: auto;
		height: calc(100vh - 13.5rem);
		display: flex;
		flex-wrap: wrap;
		.k-listview-item {
			width: 50%;
		}
	}
}

/*-----------------Serail Number Management Styles End--------------------------*/

/*-------------------Audit Report Page Styles Start----------------------*/
.default-kendo-grid {
	&.audit-report-grid {
		height: calc(100vh - 26rem) !important;
		min-height: 24rem !important;
	}
}
/*-------------------Audit Report Page Styles End----------------------*/

.gs-wrap {
	.k-animation-container.k-animation-container-relative {
		width: 100% !important;
	}
}
.not-found-text {
	color: var(--theme-text-color-secondary);
}
.form-label-cb {
	font-weight: 600;
	font-size: var(--fz-14px);
	line-height: 150%;
	color: var(--theme-text-color-secondary);
	margin-bottom: 0.1875rem;
}

/*-------------------Static Weblink Page Styles Start----------------------------*/

.end-user-container {
	&.static-weblink-page {
		.scan-img-width {
			width: 100%;
		}
		.scanned-products-wrapper .carousel {
			width: 100%;
		}
		.inline-img-wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-direction: column;
			gap: 1rem;
			width: 100%;
			margin-top: 1rem;
			.image-box {
				position: relative;
				width: 100%;
				height: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 100%;
				}
				.watermark {
					position: absolute;
					z-index: 10;
					color: var(--primary-color);
					font-size: var(--fz-40px);
					opacity: 0.5;
					word-break: break-word;
					font-weight: 700;
					padding: 2rem;
					text-align: center;
				}
			}
		}
		.endUser-footer {
			background-color: var(--success-dark-color);
			color: var(--white-color);
		}
	}
}
.flag-status {
	padding: 0.5rem 0 0.5rem 1rem;
	width: 100%;
	border-left: 5px solid var(--success-dark-color);
	display: block;
	max-width: 15rem;
	background-color: var(--info-lite-color);
	border-radius: 0.313rem;
	color: var(--success-dark-color);
	font-weight: 600;
	font-size: var(--fz-14px);
	line-height: 1.875rem;
	margin-bottom: 0.3rem;
}

/*-------------------Static Weblink Page Styles End----------------------------*/
.question-title{
	font-size: var(--fz-22px);
    line-height: 2.9375rem;
    color: var(--theme-dark-color);
    font-weight: 700;
}
.question-modal{
	.k-dialog .k-window-titlebar{
		right: 1.75rem !important;
    	top: 2.5rem !important;
	}
	.modal-body-content{
		justify-content: flex-start !important;
		padding-top: 1rem !important;
	}
	.default-form-style .form-control.text-area {
		height: 9.5rem;
		resize: none;
	}
}
/*----------Create manufacture design starts here-----*/
.add-manufacturer-module {
	.image-main-wrapper {
		display: flex;
		flex-direction: column;
		.image-wrapper {
			width: 100%;
			min-width: 6.25rem;
			max-width: 6.25rem;
			height: 100%;
			min-height: 6.25rem;
			max-height: 6.25rem;
			margin: 1rem auto;
			position: relative;
			border: 1px solid var(--border-color-secondary);

			.crop-image {
				width: calc(6.25rem - 2px);
				height: calc(6.25rem - 2px);
			}
			.close-btn {
				color: var(--theme-dark-color);
				background-color: var(--theme-light-color);
				position: absolute;
				right: 1px;
				top: 1px;
				height: 14px;
				width: 14px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border: 0;
				box-shadow: none !important;
				outline: none !important;
			}
			.fa-xmark {
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
}

/*----------Create manufacture design ends here-----*/


/*----------Location design starts here-----*/

.location-section-title {
	font-size: var(--fz-14px);
	font-weight: 600;
	margin-bottom: 1.1875rem;
	line-height: 1.3125rem;
	color: var(--theme-dark-color);
}
.default-kendo-grid {
	&.location-grid{
		max-height: 18rem !important;
		overflow-y: auto;
		padding-right: 0.75rem;
		min-height: 18rem !important;
		.k-grid-norecords{
			height: 14rem !important;
		}
	}
}

/*----------Location design end here-----*/
/*-------------------end user scan latest Styles start----------------------------*/
.end-user-product-header {
	font-size: var(--fz-14px);
    font-weight: 700;
    color: var(--theme-dark-color);
    margin: 0;
}
/*-------------------end user scan latest Styles End----------------------------*/

.text-input-area{
	&.with-addBtn{
		background-color: var(--form-control-bg);
		border: 1px solid var(--border-color-secondary);
		border-radius: 0.3125rem;
		padding: 0.5125rem 1rem;
		font-weight: 600;
		font-size: var(--fz-14px);
		line-height: 1.3125rem;
		color: var(--theme-dark-color);
		height: 2.75rem;
		font-family: "Gotham-Book", sans-serif !important;
		position: relative;
		.input-inner{
			width: calc(100% - 3rem);
			display: inline-block;
			white-space: nowrap;
			overflow: auto;
			&.input-inner-placeholder{
				color: var(--input-placeholder);
				font-size: 10px;
				font-weight: 500;
			}
		}
		.k-button {
			position: absolute;
			right: 0;
			top:0;
			bottom: 0;
			width: 2.75rem;
			background-color: var(--white-color) !important;
			color: var(--primary-color);
			border-radius: unset !important;
			border-top-right-radius: 0.3125rem !important;
    		border-bottom-right-radius: 0.3125rem !important;
			&:hover{
				background-color: var(--primary-color) !important;
    			color: var(--white-color);
			}
		}
		&.disabled{
			background-color: var(--body-color) !important;
			opacity: 1 !important;
			cursor: not-allowed !important;
		}
	}	
	&.without-addBtn{
		background-color: var(--form-control-bg);
		border: 1px solid var(--border-color-secondary);
		border-radius: 0.3125rem;
		padding: 0.5125rem 1rem;
		font-weight: 600;
		font-size: var(--fz-14px);
		line-height: 1.3125rem;
		color: var(--theme-dark-color);
		height: 2.75rem;
		font-family: "Gotham-Book", sans-serif !important;
		position: relative;
		.input-inner{
			width: 100%;
			display: inline-block;
			white-space: nowrap;
			overflow: auto;
		}
		.k-button{
			display: none
		}
		&.disabled{
			background-color: var(--body-color) !important;
			opacity: 1 !important;
			cursor: not-allowed !important;
		}
	}
}
.end-user-container{
	.product-super-script{
		u{
			padding-bottom: 0.15rem;
			border-bottom: 3px solid var(--black-color);
			text-decoration: none;
		}
		*{
			font-weight: 600 !important;
			font-size: var(--fz-20px) !important;
			line-height: 1.875rem !important;
			color: var(--secondary-dark-color) !important;
			padding: 0 0 0.15rem 0 !important;
			margin-bottom: 0 !important;
			sub, sup {
				position: relative;
				font-size: .75em !important;
				line-height: 0 !important;
				vertical-align: baseline;
				font-weight: 400 !important;
			}
		}
	}
}

.grid-tooltip-info-icon{
	width: 1.75rem;
    min-width: 1.75rem;
    height: 1.75rem;
    border-radius: 0.875rem;
	font-size: var(--fz-15px);
	line-height: 1.125rem;
	font-weight: 700;
	color: var(--theme-color);
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--warning-lite-color);
}
.tooltip-address {
	.tooltip-inner{
		padding: 0.5rem 0.75rem !important;		
		min-height: 10rem !important;
		min-width: 10rem !important;
		span{
			width: 100%;
			display: block;
			text-align: left;
		}
	}
}
.truncate-2-line{
	display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.atlas-map .map-copyright{
	display: none !important;
}

.page-content.add-product-group-module.page-scroll{
	overflow-y: auto;
    max-height: 100%;
    height: auto !important;
	scrollbar-width: thin !important;
	scrollbar-color: var(--scrollbar-thumb-2) var(--scrollbar-track-2) !important;
	&::-webkit-scrollbar {
		width: 0.75rem;
		height: 0.75rem;
		border-radius: 0rem;
	}
	
	&::-webkit-scrollbar-track {
		background-color: var(--scrollbar-track-2);
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: var(--scrollbar-thumb-2);
		border-radius: 0rem;
		&:hover {
			background-color: var(--scrollbar-thumb-hover-2);
		}
	}
	.weblink-report-grid.end-user-report-grid {
		height: auto !important;
		min-height: unset !important;
		
	}
	.default-kendo-grid.weblink-report-grid {
		min-height: 5rem !important;
		height: auto !important;
	}
	.k-pager{
		margin-top: 0.875rem !important;
	}
	.product-order-details-container{
		min-height: calc(100vh - 10.75rem) !important;
		padding-bottom: 6.5rem;
		height: auto !important;
	}
	.page-content-body {
		min-height: calc(100vh - 9.35rem);
		height: auto !important;
	}
	.default-kendo-grid.audit-report-grid {
		height: 100% !important;
		max-height: 34rem;
	}
}

.default-kendo-grid{
	&.page-scroll-no-data{
		max-height: 20rem !important;
		min-height: 20rem;
		.k-grid-header{
			padding-right: 0 !important;
		}
		.k-grid-container{
			height: 100% !important;
			max-height: 20rem !important;
			*{
				height: 100% !important;
			}
			
			.k-grid-content{
				overflow: visible !important;
				overflow-x: auto !important;
				height: 12rem !important;
				max-height: 20rem !important;
				tr.k-grid-norecords td{
					height: 100% !important;
					max-height: 20rem !important;
				}
				.k-height-container{
					height: 0 !important
				}
			}

		}
	}
}

.select-all-check{
	font-weight: 600;
	color: var(--theme-text-color-secondary);
    font-size: var(--fz-14px);
}

/*-------------------Responsive Styles Start----------------------------*/

@import './responsive-styles.scss';

/*-------------------Responsive Styles End----------------------------*/
